import React, { useEffect, useState } from "react";
import InputLabel from "../../components/utils/InputLabel";
import InputField from "../../components/utils/InputField";
import TextAreaInput from "../../components/utils/TextAreaInput";
import InputNumber from "../../components/utils/InputNumber";
import PriceInput from "../../components/utils/InputPrice";
import { Button } from "@windmill/react-ui";
import { MdCloudUpload } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import useUploader from "../../hooks/useUploader";
import Dropdown from "../../components/utils/Dropdown";
import useCategoryView from "../../hooks/useCategoryView";
import SubCategorySelector from "../../components/utils/SubCategorySelector";
import api from "../../api";
import useGetApi from "../../hooks/useGetApi";
import { FaPlus } from "react-icons/fa";
import SuccessModal from "../../drawers/SuccessModal";
import ErrorAlert from "../../drawers/ErrorAlert";
import notifyError from "../../drawers/ErrorAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchDropDown from "../../components/utils/SearchDropDown";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const { category } = useCategoryView();
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [brand, setBrand] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const navigation = useNavigate();

  // State for General
  const [sku, setSku] = useState("");
  const [returnIn, setReturnIn] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [selectedAttributeDescription, setSelectedAttributeDescription] =
    useState();
  const [referalAmount, setReferalAmount] = useState("");
  // State for Shipping
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // State for Attributes
  const [attributeType, setAttributeType] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  const [quantity, setQuantity] = useState();
  const [selectedOption, setSelectedOption] = useState({
    name: "Select an option",
  });
  const handleSelect = (option) => {
    setSelectedOption(option);
  };
  useEffect(() => {
    notifyError("An error occurred!");
  }, []);
  console.log(selectedThumbnail, selectedImages, "_______");

  // const { uploadFile, uploadProgress, uploadedFileUrl, uploadError } =
  //   useUploader();
  const [progress, setProgress] = useState(0);
  // const handleThumbnailUpload = async (event) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     const imageUrl = await uploadFile(file, true);
  //     if (imageUrl) {
  //       setSelectedThumbnail(imageUrl);
  //     }
  //   }
  // };
  // console.log(uploadedFileUrl);
  const [uploadError, setUploadError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  console.log(thumbnailLoading, "_____________thumbnail loading______");
  const handleImageUpload = async (event, thumbnail) => {
    if (thumbnail) {
      setThumbnailLoading(true);
    } else {
      setLoading(true);
    }
    const file = event.target.files[0];

    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await api.post("main/upload/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setProgress(progress);
          },
        });

        console.log(response.url, "_______res____");
        if (response && response.url) {
          const newImage = { image: response.url, thumbnail: thumbnail };

          // Wrap the state updates in a Promise to wait until they are completed
          await new Promise((resolve) => {
            if (thumbnail) {
              setSelectedThumbnail(response.url);
              setThumbnailLoading(false);
            }
            setLoading(false);
            setSelectedImages((prev) => [...prev, newImage]);

            // Resolve the promise after updating the state
            resolve();
          });
        } else {
          setUploadError("Failed to get file URL from the server.");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadError("Failed to upload the file.");
      }
    }
  };
  console.log(selectedImages, "_______+=+++++");

  const [selectedAttribute, setSelectedAttribute] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [gstPrice, setGstPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  console.log(selectedAttribute, "___________aler");
  const validateForm = () => {
    let error = null;
    if (!productName) {
      error = "Product name is required";
      return error;
    }
    if (!productDescription) {
      error = "Product description is required";
      return error;
    }
    if (!subCategory) {
      error = "Please select a subcategory";
      return error;
    }
    if (!sku) {
      error = "SKU is required";
      return error;
    }
    if (!returnIn || returnIn <= 0) {
      error = "Return period must be greater than 0";
      return error;
    }
    if (!actualPrice || actualPrice <= 0) {
      error = "Actual price must be greater than 0";
      return error;
    }
    if (!displayPrice || displayPrice <= 0) {
      error = "Display price must be greater than 0";
      return error;
    }
    if (!referalAmount) {
      error = "Referral amount is required";
      return error;
    }
    if (!gstPrice) {
      error = "GST price is required";
      return error;
    }
    if (!cashBack) {
      error = "Cashback amount is required";
      return error;
    }
    if (!weight || weight <= 0) {
      error = "Weight must be greater than 0";
      return error;
    }
    if (!length || length <= 0) {
      error = "Length must be greater than 0";
      return error;
    }
    if (!width || width <= 0) {
      error = "Width must be greater than 0";
      return error;
    }
    if (!height || height <= 0) {
      error = "Height must be greater than 0";
      return error;
    }
    if (selectedImages.length === 0) {
      error = "At least one image is required";
      return error;
    }
    if (!selectedThumbnail) {
      error = "A thumbnail is required";
      return error;
    }
    return null;
  };
  const handleSubmit = () => {
    const error = validateForm();
    console.log(error);
    if (error) {
      // notifyError(error);
      alert(error)
      // return;
    } else {
      api
        .post("products/admin/addProduct/", {
          name: productName,
          description: productDescription,
          price: displayPrice,
          purchase_price: actualPrice,
          return_in: returnIn,
          subcategory_id: subCategory,
          attributes: attributeArray,
          images: selectedImages,
          referal_amount: referalAmount,
          cashback: cashBack,
          gst_price: gstPrice,
          width: width,
          height: height,
          length: length,
          weight: weight,
          product_code: selectedParent.product_code,
        })
        .then((res) => {
          if (res.app_data.StatusCode === 6000) {
            setSuccess(true);
            setSelectedImages([]);
            setSelectedThumbnail();
            setProductName("");
            setReferalAmount();
            setProductDescription("");
            setActualPrice("");
            setDisplayPrice("");
            setReturnIn("");
            setWidth("");
            setHeight("");
            setLength("");
            setWeight("");
            setAttributeArray([]);
            setSelectedParent(res.app_data.data.products);
            navigation("/products");
          } else {
            setError(true);
            setErrorMessage(res.app_data.data.errors);
            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        });
    }
  };
  const { data: attributes } = useGetApi("products/view-attribute-type/");
  const [attributeValues, setAttributeValues] = useState();
  const { data: productCodes } = useGetApi("products/view-productcode/");
  console.log(productCodes, "_________hello0");
  useEffect(() => {
    api
      .get(`products/view-attribute-description/${selectedAttribute?.id}`)
      .then((res) => {
        if (res.app_data.StatusCode === 6000) {
          setAttributeValues(res.app_data.data);
        }
      });
  }, [selectedAttribute]);
  const [attributeArray, setAttributeArray] = useState([]);
  const [cashBack, setCashback] = useState();
  const handleAdd = () => {
    setAttributeArray((prev) => [
      ...prev,
      {
        id: attributeArray.length + 1,
        attributeName: selectedAttributeDescription.value,
        attributeDescription: selectedAttributeDescription.id,
        sku: attSku,
        quantity: quantity,
      },
    ]);
    setSelectedAttributeDescription();
    setQuantity("");
    setAttSku("");
  };
  const handleQuantity = (e, id) => {
    console.log(e, id, "_____id____");

    // Map over the array and update the quantity for the matching id
    const updatedArray = attributeArray.map((item) => {
      if (item.id === id) {
        return { ...item, quantity: e };
      }
      return item;
    });

    // Set the state with the updated array
    setAttributeArray(updatedArray);
  };

  const handleSku = (e, id) => {
    const updatedArray = attributeArray.map((item) => {
      if (item.id === id) {
        return { ...item, sku: e };
      }
      return item;
    });
    setAttributeArray(updatedArray);
  };

  const handleAttDelete = (id) => {
    const updatedArray = attributeArray.filter((item) => item.id !== id);
    setAttributeArray(updatedArray);
  };
  console.log(attributeArray, "__________");
  const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const handleSelect2 = (selectedOption) => {
    console.log("Selected Option:", selectedOption);
    // Add your logic for handling the selected option
  };
  const [isParent, setIsParent] = useState(false);
  const [selectedParent, setSelectedParent] = useState({});
  const [varients, setVarients] = useState([]);
  const [attSku, setAttSku] = useState("");
  useEffect(() => {
    setSelectedParent({});
    setVarients([]);
  }, [isParent]);
  console.log(selectedParent, "_______________________");
  useEffect(() => {
    if (selectedParent.product_code) {
      api
        .get("products/get-varients/", {
          params: {
            product_code: selectedParent.product_code,
          },
        })
        .then((res) => {
          const data = res.app_data;
          if (data.StatusCode === 6000) {
            setVarients(data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching variants:", error);
        });
    }
  }, [selectedParent]);
  console.log(progress, "___________progreeeeee");
  return (
    <div>
      <div>
        <h3 className="text-white font-semibold " style={{ fontSize: "20px" }}>
          Add Products
        </h3>
        <h6 className="text-white" style={{ fontSize: "14px" }}>
          Product/add-product
        </h6>
      </div>
      <div className="bg-gray-800 p-5 mt-4 rounded">
        <div className="flex gap-3 py-4 border-b border-gray-200 border-solid justify-between">
          <div className="flex gap-3">
            {varients?.map((item) => (
              <div
                className="border border-gray-600 border-solid py-2 px-2 h-10 flex justify-center item-center cursor-none rounded-md text-gray-500"
                style={{ whiteSpace: "nowrap", alignItems: "center" }}
              >
                <h4>{item?.name}</h4>
              </div>
            ))}
            <div
              className="border border-gray-400 px-2 border-dashed h-10 flex justify-center item-center rounded-md text-gray-400 cursor-pointer"
              style={{ alignItems: "center", whiteSpace: "nowrap" }}
            >
              <h4 style={{ whiteSpace: "nowrap" }}>
                {productName ? productName : "+ Add Product"}
              </h4>
            </div>
          </div>
          <div>
            <button className="bg-gray-600 py-2 px-5 rounded text-white">
              Reset
            </button>
          </div>
        </div>
        <div className="pt-4 flex w-full" style={{ height: "65vh" }}>
          <div className="w-8/12 border-r border-gray-400 pr-4 divide-y h-full overflow-y-scroll scrollbar-hidden">
            <div>
              <h4 className="text-white">Product Detail</h4>
              <div className="flex gap-2 items-center py-2">
                <input
                  type="checkbox"
                  name="isparent"
                  checked={isParent}
                  onChange={() => {
                    setIsParent((prev) => !prev);
                  }}
                />
                <label for="isparent" className="text-white">
                  product is parent
                </label>
              </div>
              {!isParent && (
                <div className="flex py-4">
                  <InputLabel label={"select parent"} width="30%" />
                  <SearchDropDown
                    options={productCodes}
                    onSelect={(e) => setSelectedParent(e)}
                  />
                </div>
              )}
              <div className="py-3 flex">
                <InputLabel label="Product Name" width="30%" />
                <InputField
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Product Description" width="30%" />
                <TextAreaInput
                  onChange={(e) => setProductDescription(e.target.value)}
                  value={productDescription}
                />
              </div>
              {/* <div className="py-3 flex">
                <InputLabel label="Brand" width="30%" />
                <InputField />
              </div> */}
              <div className="py-3 flex">
                <InputLabel label="Category" width="30%" />
                <Dropdown
                  options={category}
                  selectedOption={selectedOption}
                  onSelect={handleSelect}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Sub Category" width="30%" />
                <SubCategorySelector
                  catId={selectedOption.id}
                  onSelect={(e) => setSubCategory(e.key)}
                />
              </div>
            </div>
            <div className="pt-4">
              <h4 className="text-white">General</h4>
              <div className="py-3 flex">
                <InputLabel label="SKU" width="30%" />
                <InputField
                  onChange={(e) => setSku(e.target.value)}
                  value={sku}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Return in" width="30%" />
                <InputNumber
                  onChange={(e) => setReturnIn(e)}
                  value={returnIn}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Actual Price" width="30%" />
                <PriceInput
                  onChange={(e) => setActualPrice(e)}
                  value={actualPrice}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="GST in percent" width="30%" />
                <PriceInput onChange={(e) => setGstPrice(e)} value={gstPrice} />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Display Price" width="30%" />
                <PriceInput
                  onChange={(e) => setDisplayPrice(e)}
                  value={displayPrice}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Refferal amount" width="30%" />
                <PriceInput
                  onChange={(e) => setReferalAmount(e)}
                  value={referalAmount}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Cashback amount" width="30%" />
                <PriceInput onChange={(e) => setCashback(e)} value={cashBack} />
              </div>
            </div>
            <div className="pt-4">
              <h4 className="text-white">Shipping</h4>
              <div className="py-3 flex">
                <InputLabel label="Weight" width="30%" />
                <InputNumber onChange={(e) => setWeight(e)} value={weight} />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Length (cm)" width="30%" />
                <InputNumber onChange={(e) => setLength(e)} value={length} />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Width (cm)" width="30%" />
                <InputNumber onChange={(e) => setWidth(e)} value={width} />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Height (cm)" width="30%" />
                <InputNumber onChange={(e) => setHeight(e)} value={height} />
              </div>
            </div>
            <div className="pt-4">
              <h4 className="text-white">Attributes</h4>
              <div className="py-3 flex">
                <InputLabel label="Attribute type" width="30%" />
                <Dropdown
                  options={attributes}
                  onSelect={(e) => setSelectedAttribute(e)}
                  selectedOption={selectedAttribute}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Attribute Value" width="30%" />
                <Dropdown
                  options={attributeValues}
                  onSelect={(e) => setSelectedAttributeDescription(e)}
                  selectedOption={selectedAttributeDescription}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Quantity" width="30%" />
                <InputNumber
                  onChange={(e) => setQuantity(e)}
                  value={quantity}
                />
              </div>
              <div className="py-3 flex">
                <InputLabel label="Sku" width="30%" />
                <InputField
                  onChange={(e) => setAttSku(e.target.value)}
                  value={attSku}
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="bg-blue-500 rounded-md p-2 flex gap-2 items-center"
                  onClick={() => handleAdd()}
                >
                  <FaPlus color="white" size={20} />{" "}
                  <p className="text-white font-bold">Add</p>
                </button>
              </div>
              <div className="flex gap-5 mt-5 py-3 flex-wrap">
                {/* {attributeArray?.map((item) => (
                    <span className="bg-gray-300 p-2 rounded">
                      {item.attributeName}-{item.quantity}
                    </span>
                  ))} */}
                {attributeArray.map((item) => (
                  <div
                    className="flex justify-between border border-gray-400 p-2 rounded"
                    style={{ width: "48%" }}
                  >
                    <div>
                      <h5 className="text-white">{item.attributeName}</h5>
                    </div>
                    <div>
                      <input
                        type="number"
                        className="rounded-md bg-gray-700 border border-gray-400 w-20 text-gray-200 px-2"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantity(e.target.value, item.id)
                        }
                      />
                    </div>
                    <div>
                      <input
                        type="number"
                        className="rounded-md bg-gray-700 border border-gray-400 w-20 text-gray-200 px-2"
                        value={item.sku}
                        placeholder="Sku"
                        onChange={(e) => handleSku(e.target.value, item.id)}
                      />
                    </div>
                    <div>
                      <h4
                        className="text-red-500 cursor-pointer"
                        onClick={() => handleAttDelete(item.id)}
                      >
                        <DeleteIcon />
                        delete
                      </h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-4/12 pl-3 overflow-y-scroll">
            {progress > 0 && progress < 100 && (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
            <div
              className="border border-gray-600 border-dashed rounded flex justify-center items-center"
              style={{ height: "200px" }}
            >
              <label
                htmlFor="thumbnailInput"
                className="w-full h-full flex flex-col items-center justify-center"
                style={{ height: "200px" }}
              >
                <input
                  type="file"
                  id="thumbnailInput"
                  className="hidden"
                  onChange={(e) => handleImageUpload(e, true)}
                />
                {selectedThumbnail ? (
                  <img
                    src={selectedThumbnail}
                    alt="Uploaded Thumbnail"
                    className="w-full h-full object-contain rounded"
                  />
                ) : thumbnailLoading ? (
                  <Loader />
                ) : (
                  <>
                    <MdCloudUpload
                      size={70}
                      color="white"
                      style={{ marginBottom: "8px" }}
                    />
                    <h4 className="text-white">Upload Thumbnail</h4>
                  </>
                )}
              </label>
              <h4 className="text-gray-500 mt-2">Max 1 Image</h4>
            </div>
            <h4 className="text-white">Max 9 photos</h4>
            <div className="flex gap-3 flex-wrap">
              <div className="mt-2 flex">
                <label
                  htmlFor="fileInput"
                  className="border border-gray-600 border-dashed rounded flex items-center justify-center"
                  style={{ width: "100px", height: "100px" }}
                >
                  <input
                    type="file"
                    id="fileInput"
                    className="hidden"
                    disabled={selectedImages.length === 10}
                    onChange={(e) => handleImageUpload(e, false)}
                  />
                  {loading ? (
                    <Loader />
                  ) : (
                    <IoAddCircleOutline size={50} color="white" />
                  )}
                </label>
              </div>
              {/* Render other selectedImages elements here */}
              {selectedImages
                .filter((item) => item.thumbnail === false)
                .map((image, index) => (
                  <div
                    key={index}
                    className="relative"
                    style={{ width: "100px", height: "100%" }}
                  >
                    <button
                      onClick={() => {
                        const newSelectedImages = selectedImages.filter(
                          (_, imgIndex) => imgIndex !== index
                        );
                        setSelectedImages(newSelectedImages);
                      }}
                      className="absolute bg-red-500 text-white p-1 rounded-full flex justify-center items-center"
                      style={{
                        width: "20px",
                        height: "20px",
                        fontSize: 10,
                        top: "2px",
                        right: "2px",
                      }}
                    >
                      X
                    </button>
                    {image.loading ? (
                      <div className="w-full h-full flex justify-center items-center">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <img
                        src={image.image}
                        alt={`Uploaded Image ${index + 1}`}
                        className="w-full h-full object-contain rounded"
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-gray-800 mt-3 rounded flex justify-end"
        style={{ height: "60px" }}
      >
        <div
          className="justify-end h-full flex item-center py-3 pr-3"
          style={{}}
        >
          <Button
            className="w-100"
            style={{ backgroundColor: "orange" }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </div>
      <SuccessModal
        isOpen={success}
        message="Succesfully Added Product"
        onClose={() => setSuccess(false)}
      />
      <ErrorAlert
        isOpen={error}
        onClose={() => setError(false)}
        message={errorMessage}
      />
    </div>
  );
};

export default AddProduct;
